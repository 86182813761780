<template>
  <app-timeline>
    <app-timeline-item
      v-for="(leave,index) in data"
      :key="index"
      :title="`${leave.Label}`"
      :subtitle="leave.Description"
      :description="'Period: ' + leave.StartDate + ' ~ ' + leave.EndDate"
      icon="ClockIcon"
      :time="leave.UpdatedAt | date"
      variant="success"
      :data="leave"
      :showDelete="false"
      :onDelete="onDelete"
    />

  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from './CandidateTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    onDelete: {
      type: Function,
      default: null
    }
  },
  methods: {
  }
}
</script>
