<template>
  <app-timeline>
    <app-timeline-item
      v-for="(rating,index) in data"
      :key="index"
      :title="`${rating.UserName} left a rating`"
      :subtitle="rating.Comment + ''"
      icon="StarIcon"
      :time="rating.UpdatedAt | date"
      variant="success"
      :data="rating"
      :show-delete="true"
      :onDelete="onDelete"
      :rating="rating.Rating"
      :ratingType="ratingTypes[rating.ScoreType]"
      :performanceRating="rating"
      :performanceRatingName="getRatingName(rating.RatingName)"
      :performanceRatingWordOptions="wordOptions"
      :performanceRatingPeriodOptions="periodOptions"
      :performanceRatingMonthNameOptions="monthNameOptions"
      :performanceRatingYearNameOptions="yearNameOptions"
      :performanceRatingSemesterNameOptions="semesterNameOptions"
      :performanceRatingQuarterNameOptions="quarterNameOptions"
    />

  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from './CandidateTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    onDelete: {
      type: Function,
      default: null
    },
    ratingTypes: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    wordOptions: {
      type: Object,
      default: null
    },
    periodOptions: {
      type: Object,
      default: null
    },
    monthNameOptions: {
      type: Object,
      default: null
    },
    yearNameOptions: {
      type: Object,
      default: null
    },
    semesterNameOptions: {
      type: Object,
      default: null
    },
    quarterNameOptions: {
      type: Object,
      default: null
    },
  },
  methods: {
    getRatingName(ratingName) {
      const ratingType = this.options.filter(option => option.value.split('|')[0] == (ratingName + ''))[0]
      return ratingType?.text
    }
  }
}
</script>
