<template>
  <app-timeline>
    <app-timeline-item
      v-for="(todo,index) in data"
      :key="index"
      :title="`${todo.UserName}`"
      :subtitle="todo.Comment + ''"
      icon="ClipboardIcon"
      :time="todo.UpdatedAt | date"
      variant="warning"
      :data="todo"
      :show-delete="true"
      :onDelete="onDelete"
    />

  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from './CandidateTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    onDelete: {
      type: Function,
      default: null
    }
  },
  methods: {
  }
}
</script>
