<template>
  <app-timeline>
    <app-timeline-item
      v-for="(education,index) in data"
      :key="index"
      :title="`${education.Course} | ${education.Institution}`"
      :subtitle="education.Description"
      icon="BookOpenIcon"
      variant="danger"
      :time="`${education.StartDate} ~ ${education.EndDate}`"
      :showDelete="false"
    />

  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from './CandidateTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
  }
}
</script>
