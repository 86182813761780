<template>
    <app-timeline>
        <template v-for="(item,index) in data">
            <app-timeline-item
                v-if="item.type == 'Comment'"
                :key="index"
                :title="i18nT(`Comment by ${item.object.UserName}`)"
                :subtitle="item.object.Comment + ''"
                icon="MessageSquareIcon"
                :time="item.object.UpdatedAt | date"
                variant="success"
                :data="item.object"
                :onDelete="onDelete"
            />
            <app-timeline-item
                v-if="item.type == 'History'"
                :key="index"
                :title="item.title"
                :subtitle="item.subtitle"
                :icon="item.action == 'left' ? 'PauseIcon' : 'PlayIcon'"
                :time="item.createdAt | date"
                :variant="item.action == 'left' ? `danger`: `success`"
                :data="item.object"
            />
            <app-timeline-item
                v-if="item.type == 'Interview'"
                :key="index"
                :title="item.object.UserName + i18nT(`scheduled an interview`)"
                :subtitle="item.object.Comment + ''"
                icon="CalendarIcon"
                :time="item.object.CreatedAt | date"
                variant="danger"
                :data="item.object"
                :onDelete="onDelete"
                :when="item.object.InterviewDate"
                :with_="item.object.UserName"
                :where="item.object.Location"
            />
            <app-timeline-item
                v-if="item.type == 'Rating'"
                :key="index"
                :title="`${item.object.UserName}`"
                :subtitle="item.object.Comment + ''"
                icon="StarIcon"
                :time="item.object.UpdatedAt | date"
                variant="success"
                :data="item.object"
                :onDelete="onDelete"
                :rating="item.object.Rating"
                :ratingType="ratingTypes[item.object.ScoreType]"
            />
            <app-timeline-item
                v-if="item.type == 'Todo'"
                :key="index"
                :title="`${item.object.UserName}`"
                :subtitle="item.object.Comment + ''"
                icon="ClipboardIcon"
                :time="item.object.UpdatedAt | date"
                variant="warning"
                :data="item.object"
                :onDelete="onDelete"
            />
        </template>

    </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from './CandidateTimelineItem.vue'


export default {
    components: {
        AppTimeline,
        AppTimelineItem,
    },
    data() {
        return {

        }
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        onDelete: {
            type: Function,
            default: null
        },
        ratingTypes: {
            type: Array,
            default: () => []
        }
    },
}
</script>
