<template>
    <div v-if="employeeDetails">


        <b-row>
            <!-- Employee TABS -->
            <b-col cols="7">

                <b-card
                    class="section-container"
                    no-body
                >
                    <b-card-body>
                        <div class="d-flex">
                            <div>
                                <h4 class="mb-1">{{ i18nT(`Employment details`) }}</h4>
                            </div>
                        </div>

                        <hr class="invoice-spacing"/>

                        <b-tabs
                            vertical
                        >
                            <b-tab>
                                <template #title>
                                    <div class="text-left">
                                        <feather-icon icon="DollarSignIcon" />
                                        <span>{{i18nT(`Contract information`)}}</span>
                                    </div>
                                </template>

                                <!-- JOB TITLE -->
                                <b-row>
                                    <b-col cols="6">
                                        <h6 class="section-label">{{ i18nT(`Job title`) }}</h6>
                                        <p v-if="employeeDetails.employee.Position" >{{employeeDetails.employee.Position}}</p>
                                        <p v-else class="text-muted">{{i18nT(`N/A`)}}</p>
                                    </b-col>

                                    <b-col cols="6">
                                        <h6 class="section-label">{{ i18nT(`Organization job level`) }}</h6>
                                        <p v-if="contractData.OnOrgJobLevel">{{jobLevelLabel(contractData.OnOrgJobLevel)}}</p>
                                        <p v-else class="text-muted">{{i18nT(`N/A`)}}</p>
                                    </b-col>

                                </b-row>

                                <b-row>
                                    <b-col cols="12">
                                        <hr class="invoice-spacing"/>
                                    </b-col>
                                </b-row>

                                <!-- CONTRACT TYPE AND DEPT -->
                                <b-row>

                                    <b-col cols="6">
                                        <h6 class="section-label">{{ i18nT(`Contract type`) }}</h6>
                                        <p v-if="contractData.OnContractType">{{contractTypeLabel(contractData.OnContractType)}}</p>
                                        <p v-else class="text-muted">{{i18nT(`N/A`)}}</p>
                                    </b-col>
                                    <b-col cols="6">
                                        <h6 class="section-label">{{i18nT(`Department`)}}</h6>
                                        <p v-if="contractData.OnDepartment">{{departmentLabel(contractData.OnDepartment)}}</p>
                                        <p v-else class="text-muted">{{i18nT(`N/A`)}}</p>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12">
                                        <hr class="invoice-spacing"/>
                                    </b-col>
                                </b-row>

                                <!-- KEY DATES -->
                                <b-row>
                                    <b-col cols="4">
                                        <h6 class="section-label">{{i18nT(`Start date`)}}</h6>
                                        <p v-if="contractData.OnStartingFrom">{{contractData.OnStartingFrom | date}}</p>
                                        <p v-else class="text-muted">{{i18nT(`N/A`)}}</p>
                                    </b-col>

                                    <b-col cols="4">
                                        <h6 class="section-label">{{i18nT(`Evaluation period end`)}}</h6>
                                        <p v-if="contractData.OnEvaluationPeriod">{{contractData.OnEvaluationPeriod | date}}</p>
                                        <p v-else class="text-muted">{{i18nT(`N/A`)}}</p>
                                    </b-col>

                                    <b-col cols="4">
                                        <h6 class="section-label">{{i18nT(`End date`)}}</h6>
                                        <p v-if="contractData.OnEnding">{{contractData.OnEnding | date}}</p>
                                        <p v-else class="text-muted">{{i18nT(`N/A`)}}</p>
                                    </b-col>
                                </b-row>


                                <b-row>
                                    <b-col cols="12">
                                        <hr class="invoice-spacing"/>
                                    </b-col>
                                </b-row>

                                <!-- SALARY -->
                                <b-row>
                                    <b-col cols="4">
                                        <h6 class="section-label">{{i18nT(`Net Salary`)}}</h6>
                                        <p v-if="contractData.OnSalaryNet">{{ {currency: currency, amount: contractData.OnSalaryNet} | currency }}/{{ salaryPeriodLabel(contractData.OnSalaryType)}}</p>
                                        <p v-else class="text-muted">{{i18nT(`N/A`)}}</p>
                                    </b-col>

                                    <b-col cols="4">
                                        <h6 class="section-label">{{i18nT(`Gross Salary`)}}</h6>
                                        <p v-if="contractData.OnSalaryGross">{{ {currency: currency, amount: contractData.OnSalaryGross} | currency}}/{{ salaryPeriodLabel(contractData.OnSalaryType) }}</p>
                                        <p v-else class="text-muted">{{i18nT(`N/A`)}}</p>
                                    </b-col>

                                    <b-col cols="4">
                                        <h6 class="section-label">{{i18nT(`Work hours`)}}</h6>
                                        <p v-if="contractData.WorkHours">{{ contractData.WorkHours+' '+i18nT(`hours`)}}/{{ salaryPeriodLabel(contractData.WorkType) }}</p>
                                        <p v-else class="text-muted">{{i18nT(`N/A`)}}</p>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12">
                                        <hr class="invoice-spacing"/>
                                    </b-col>
                                </b-row>

                                <!-- Schedule -->
                                <b-row>
                                    <b-col cols="12">
                                        <h6 class="section-label">{{i18nT(`Schedule`)}}</h6>
                                        <p>
                                            <b-badge
                                                class="mr-1"
                                                pill
                                                :variant="worksOnThisDay(workDay.value) ? 'light-primary' : 'light-secondary'"
                                                v-for="workDay in weekDays" :key="'day-'+workDay.text" >{{ workDay.text }}</b-badge>
                                        </p>
                                    </b-col>
                                </b-row>




                                <div v-if="employeeDetails.employee && employeeDetails.employee.RequiredSalary" class="">
                                    <hr class="invoice-spacing" />
                                    <h6 class="section-label">{{i18nT(`Required Salary`)}}</h6>
                                    <div class="detail-field">
                                        {{ employeeDetails.employee.RequiredSalary }}
                                    </div>
                                </div>

                            </b-tab>

                            <b-tab>
                                <template #title>
                                    <div class="text-left">
                                        <feather-icon icon="ListIcon" />
                                        <span>{{i18nT(`Timeline`)}}</span>
                                    </div>
                                </template>
                                <div>
                                    <timeline
                                        :data="employeeDetails.tabs.timeline.data ? Object.values(employeeDetails.tabs.timeline.data): []"
                                        :ratingTypes="employeeDetails.lists ? employeeDetails.lists.rating_types : []"
                                        class="mt-2 mb-2"
                                    />

                                </div>
                            </b-tab>

                            <b-tab>
                                <template #title>
                                    <div class="text-left">
                                        <feather-icon icon="ClockIcon" />
                                        <span>{{i18nT(`Time off`)}}</span>
                                    </div>
                                </template>
                                <div>
                                    <leaves
                                        v-if="employeeDetails.tabs.leaves"
                                        :data="employeeDetails.tabs.leaves.data"
                                        class="mt-2 mb-2"
                                    />
                                </div>
                            </b-tab>

                            <!-- TASKS -->
                            <b-tab>
                                <template #title>
                                    <div class="text-left">
                                        <feather-icon icon="CheckCircleIcon" />
                                        <span>{{i18nT(`Tasks`)}}</span>
                                    </div>
                                </template>
                                <div>
                                    <div>
                                        <p>{{i18nT(`Assign and manage tasks for this employee. You can add individual tasks or group templates.`)}}</p>
                                        <b-button
                                            variant="primary"
                                            size=""
                                            @click="openAssignTaskModal"
                                        >
                                            <feather-icon
                                                icon="PlusCircleIcon"
                                                size="16"
                                            />
                                            {{i18nT(`Add new`)}}
                                        </b-button>
                                    </div>
                                    <div
                                        v-for="(task ,index) in employeeDetails.tabs.tasks.data"
                                        :key="'task-'+index"
                                    >
                                        <div class="task-container d-flex">
                                            <div class="d-inline-block mt-1">
                                                <b-form-checkbox
                                                    :name="task.Id + ''"
                                                    :checked="task.Completed == 1"
                                                    @change="onFinishTask(task)"
                                                />
                                            </div>
                                            <div class="d-inline-block pl-1">
                                                <h5
                                                    class="task-title"
                                                    v-bind:class="{completed: task.Completed == 1}"
                                                >
                                                    {{task.Label}}
                                                </h5>
                                                <span class="task-type" v-bind:class="{completed: task.Completed == 1}">{{task.TaskType}}</span>

                                                <b-button
                                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                    variant="outline-primary"
                                                    size="sm"
                                                    class="removeBtn"
                                                    @click="removeTask(task)"
                                                >
                                                    <feather-icon
                                                        icon="Trash2Icon"
                                                        size="16"
                                                        class="m-0"
                                                    />
                                                </b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>

                            <!-- FILES -->
                            <b-tab>
                                <template #title>
                                    <feather-icon icon="FolderIcon" />
                                    <span>{{i18nT(`Documents`)}}</span>
                                </template>
                                <div>
                                    <div>
                                        <p>{{i18nT(`Assign and manage documents for this employee.`)}}</p>
                                        <b-button
                                            variant="primary"
                                            size=""
                                            @click="openAssignDocumentModal"
                                        >
                                            <feather-icon
                                                icon="PlusCircleIcon"
                                                size="16"
                                            />
                                            {{i18nT(`Add new`)}}
                                        </b-button>
                                    </div>
                                    <documents
                                        v-if="employeeDetails.tabs.documents"
                                        :data="employeeDetails.tabs.documents.data"
                                        class="mt-2 mb-2"
                                        :onDelete="onDeleteDocument"
                                    />

                                </div>
                            </b-tab>

                            <!-- PERFORMANCE -->
                            <b-tab>
                                <template #title>
                                    <feather-icon icon="StarIcon" />
                                    <span>{{i18nT(`Performance`)}}</span>
                                </template>
                                <ratings
                                    :data="employeeDetails.tabs.performance.data"
                                    :onDelete="onDeletePerformanceRating"
                                    :options="performanceRatingNames"
                                    :wordOptions="employeeDetails.performance_rating_words"
                                    :periodOptions="employeeDetails.performance_rating_period"
                                    :monthNameOptions="employeeDetails.performance_rating_period_monthly"
                                    :yearNameOptions="employeeDetails.performance_rating_period_years"
                                    :semesterNameOptions="employeeDetails.performance_rating_period_bi_annual"
                                    :quarterNameOptions="employeeDetails.performance_rating_period_quarterly"
                                    class="mt-2 mb-2"
                                />
                                <validation-observer ref="ratingForm">
                                    <b-row>
                                        <b-col cols="6">
                                            <b-form-group
                                                :label="i18nT(`Performance rating type`)"
                                                label-for="ratingType"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Perofrmance rating type"
                                                    rules="required"
                                                >
                                                    <b-form-select
                                                        v-model="performanceRatingName"
                                                        :options="performanceRatingNames"
                                                        :state="errors.length > 0 ? false:null"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                            <b-form-group
                                                :label="i18nT(`Rating`)"
                                                label-for="rating"
                                                v-if="ratingTypeName=='stars'"
                                            >
                                                <b-form-rating v-model="rating"></b-form-rating>
                                            </b-form-group>
                                            <b-form-group
                                                :label="i18nT(`Words`)"
                                                label-for="words"
                                                v-if="ratingTypeName=='words'"
                                            >
                                                <b-form-select
                                                    v-model="performanceRatingWord"
                                                    :options="performanceRatingWords"
                                                />
                                            </b-form-group>
                                            <b-form-group
                                                :label="i18nT(`Percentage`)+` ${performanceRatingPercentage}%`"
                                                label-for="percentage"
                                                v-if="ratingTypeName=='percentage'"
                                            >
                                                <b-form-input
                                                    v-model="performanceRatingPercentage"
                                                    type="range"
                                                    min="0"
                                                    max="100"
                                                />
                                            </b-form-group>
                                            <b-form-group
                                                :label="i18nT(`Comment`)"
                                                label-for="comment"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Comment"
                                                    rules="required"
                                                >
                                                    <b-form-textarea
                                                        id="textarea-default"
                                                        rows="3"
                                                        v-model="ratingComment"
                                                        :placeholder="i18nT(`Enter rating comment....`)"
                                                        :state="errors.length > 0 ? false:null"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="6">
                                            <b-form-group
                                                :label="i18nT(`Performance rating period`)"
                                                label-for="ratingPeriod"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Performance rating period"
                                                    rules="required"
                                                >
                                                    <b-form-select
                                                        v-model="performanceRatingPeriodType"
                                                        :options="performanceRatingPeriodTypes"
                                                        :state="errors.length > 0 ? false:null"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                            <b-row v-if="performanceRatingPeriodType=='monthly'">
                                                <b-col cols="6">
                                                    <b-form-group
                                                        :label="i18nT(`Month`)"
                                                        label-for="month"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Month"
                                                            rules="required"
                                                        >
                                                            <b-form-select
                                                                v-model="performanceRatingMonthlyOption"
                                                                :options="performanceRatingMonthlyOptions"
                                                                :state="errors.length > 0 ? false:null"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="6">
                                                    <b-form-group
                                                        :label="i18nT(`Year`)"
                                                        label-for="year"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Year"
                                                            rules="required"
                                                        >
                                                            <b-form-select
                                                                v-model="performanceRatingYearlyOption"
                                                                :options="performanceRatingYearlyOptions"
                                                                :state="errors.length > 0 ? false:null"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row v-if="performanceRatingPeriodType=='quarterly'">
                                                <b-col cols="6">
                                                    <b-form-group
                                                        :label="i18nT(`Quarter`)"
                                                        label-for="quarter"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Quarter"
                                                            rules="required"
                                                        >
                                                            <b-form-select
                                                                v-model="performanceRatingQuarterlyOption"
                                                                :options="performanceRatingQuarterlyOptions"
                                                                :state="errors.length > 0 ? false:null"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="6">
                                                    <b-form-group
                                                        :label="i18nT(`Year`)"
                                                        label-for="year"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Year"
                                                            rules="required"
                                                        >
                                                            <b-form-select
                                                                v-model="performanceRatingYearlyOption"
                                                                :options="performanceRatingYearlyOptions"
                                                                :state="errors.length > 0 ? false:null"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row v-if="performanceRatingPeriodType=='bi_annual'">
                                                <b-col cols="6">
                                                    <b-form-group
                                                        :label="i18nT(`Semester`)"
                                                        label-for="semester"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Semester"
                                                            rules="required"
                                                        >
                                                            <b-form-select
                                                                v-model="performanceRatingBiAnualOption"
                                                                :options="performanceRatingBiAnualOptions"
                                                                :state="errors.length > 0 ? false:null"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="6">
                                                    <b-form-group
                                                        :label="i18nT(`Year`)"
                                                        label-for="year"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Year"
                                                            rules="required"
                                                        >
                                                            <b-form-select
                                                                v-model="performanceRatingYearlyOption"
                                                                :options="performanceRatingYearlyOptions"
                                                                :state="errors.length > 0 ? false:null"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row v-if="performanceRatingPeriodType=='yearly'">
                                                <b-col cols="12">
                                                    <b-form-group
                                                        :label="i18nT(`Year`)"
                                                        label-for="year"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Year"
                                                            rules="required"
                                                        >
                                                            <b-form-select
                                                                v-model="performanceRatingYearlyOption"
                                                                :options="performanceRatingYearlyOptions"
                                                                :state="errors.length > 0 ? false:null"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row v-if="performanceRatingPeriodType=='specific_period'">
                                                <b-col cols="6">
                                                    <b-form-group
                                                        :label="i18nT(`Specific Period Start`)"
                                                        label-for="specificPeriodStart"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Specific Period Start"
                                                            rules="required"
                                                        >
                                                            <flat-pickr
                                                                v-model="specificPeriodStart"
                                                                class="form-control flat-picker"
                                                                :placeholder="i18nT(`YYYY-MM-DD`)"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="6">
                                                    <b-form-group
                                                        :label="i18nT(`Specific Period End`)"
                                                        label-for="specificPeriodEnd"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Specific Period End"
                                                            rules="required"
                                                        >
                                                            <flat-pickr
                                                                v-model="specificPeriodEnd"
                                                                class="form-control flat-picker"
                                                                :placeholder="i18nT(`YYYY-MM-DD`)"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>

                                    <b-button
                                        variant="primary"
                                        @click="publishRating"
                                    >
                                        {{i18nT(`Publish`)}}
                                    </b-button>

                                </validation-observer>
                            </b-tab>

                            <!-- COMMENTS -->
                            <b-tab>
                                <template #title>
                                    <div class="text-left">
                                        <feather-icon icon="MessageSquareIcon" />
                                        <span>{{i18nT(`Comments`)}}</span>
                                    </div>
                                </template>
                                <div>
                                    <p>{{i18nT(`Add comments visible to all staff members.`)}} {{i18nT(`Comments are not visible to employees.`)}}</p>
                                    <comments
                                        :data="employeeDetails.tabs.comments.data"
                                        class="mt-2 mb-2"
                                        :onDelete="onDeleteComment"
                                    />
                                    <validation-observer ref="commentForm">
                                        <b-form-group
                                            :label="i18nT(`Comment`)"
                                            label-for="comment"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Comment"
                                                rules="required"
                                            >
                                                <b-form-textarea
                                                    id="textarea-default"
                                                    rows="3"
                                                    v-model="comment"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                        <b-button
                                            variant="primary"
                                            @click="publishComment"
                                        >
                                            {{i18nT(`Publish`)}}
                                        </b-button>

                                    </validation-observer>
                                </div>
                            </b-tab>

                            <!-- Notes -->
                            <b-tab>
                                <template #title>
                                    <feather-icon icon="ClipboardIcon" />
                                    <span> {{i18nT(`Private notes`)}}</span>
                                </template>
                                <p>{{i18nT(`Add private notes visible only to you.`)}} {{i18nT(`Notes are not visible to employees and staff members.`)}}</p>
                                <todos
                                    :data="employeeDetails.tabs.todos.data"
                                    class="mt-2 mb-2"
                                    :onDelete="onDeleteTodo"
                                />
                                <validation-observer ref="todoForm">
                                    <b-form-group
                                        :label="i18nT(`Note`)"
                                        label-for="todo"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Note"
                                            rules="required"
                                        >
                                            <b-form-textarea
                                                id="textarea-default"
                                                rows="3"
                                                v-model="todo"
                                                :state="errors.length > 0 ? false:null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                    <b-button
                                        variant="primary"
                                        @click="publishTodo"
                                    >
                                        {{i18nT(`Publish`)}}
                                    </b-button>

                                </validation-observer>
                            </b-tab>

                        </b-tabs>

                    </b-card-body>

                </b-card>

            </b-col>


            <!-- PROFILE INFO -->
            <b-col cols="5">

                <b-card
                    class="section-container"
                    no-body
                >
                    <b-card-header>
                        <h4 class="card-title">{{ i18nT(`Profile`) }}</h4>
                        <b-dropdown
                            variant="link"
                            toggle-class="p-0"
                            no-caret
                            right
                            v-if="iCan('employees', 'write')"
                        >
                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                            </template>
                            <b-dropdown-item
                                v-if="employeeDetails.employee"
                                :to="{ name: 'edit-employee', params: { id: employeeDetails.employee.Id },}"
                            >
                                <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="onDelete()">
                                <span class="align-middle ml-50 text-danger">{{i18nT(`Delete`)}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-card-header>

                    <!-- NEW PROFILE -->
                    <b-card-body>
                        <b-row>
                            <b-col cols="12">
                                <div class="d-flex w-100">
                                    <b-avatar
                                        :src="employeeDetails.employee.ImageUrl"
                                        :text="(employeeDetails.employee.FirstName ? employeeDetails.employee.FirstName.charAt(0) : '') + (employeeDetails.employee.LastName ? employeeDetails.employee.LastName.charAt(0) : '')"
                                        class="badge-light-danger mr-1 cursor-pointer"
                                        size="4rem"
                                    >
                                    </b-avatar>
                                    <div class="flex-grow-1">
                                        <h1 class="cursor-pointer">{{ employeeDetails.employee.Label }}</h1>
                                        <div class="item-rating ml-0  cursor-pointer">
                                            <ul class="unstyled-list list-inline" v-b-tooltip.hover.top="i18nT(`Employee rating: `)+employeeRating.toFixed(1)">
                                                <li
                                                    v-for="star in 5"
                                                    :key="star"
                                                    class="ratings-list-item"
                                                    :class="{'ml-25': star-1}"
                                                >
                                                    <feather-icon
                                                        icon="StarIcon"
                                                        size="16"
                                                        :class="[{'fill-current': star <= employeeRating}, star <= employeeRating ? 'text-warning' : 'text-muted']"
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="6">
                                <p class="mb-0 mt-50">{{ employeeDetails.employee.Position }}</p>
                                <p class="mb-0 mt-50">
                                    <small v-if="employeeDetails.employee.Nationality" class="text-muted">
                                        <font-awesome-icon :icon="['fas', 'globe']"  style="margin-right: 2px;"/> {{ i18nT(employeeDetails.employee.Nationality) | capFirst }} |
                                    </small>

                                    <small v-if="employeeDetails.employee.Gender" class="text-muted">
                                        <font-awesome-icon :icon="['fas', 'venus-mars']"  style="margin-right: 2px;"/>{{ i18nT(employeeDetails.employee.Gender) | capFirst }} |
                                    </small>

                                    <small v-if="employeeDetails.employee.BirthDate" class="text-muted">
                                        <font-awesome-icon :icon="['fas', 'birthday-cake']" style="margin-right: 2px;"/> {{ employeeDetails.employee.BirthDate | date }}
                                    </small>
                                </p>
                            </b-col>

                            <b-col cols="6">
                                <div class="info-item mt-50 text-truncate">
                                    <feather-icon
                                        icon="PhoneIcon"
                                        size="16"
                                    />

                                    <b-link
                                        v-if="employeeDetails.employee.Phone"
                                        :href="'tel:'+employeeDetails.employee.Phone"
                                        class="position-relative"
                                    >{{ employeeDetails.employee.Phone}}</b-link>

                                    <span v-else>{{i18nT(`Not available`)}}</span>
                                </div>
                                <div class="info-item mt-50 text-truncate">
                                    <feather-icon
                                        icon="MailIcon"
                                        size="16"
                                    />
                                    <span>
                                        <b-link
                                            :href="'mailto:'+employeeDetails.employee.Email"
                                            class="position-relative"
                                        >{{ employeeDetails.employee.Email }}</b-link>
                                    </span>
                                </div>
                            </b-col>


                        </b-row>

                        <!-- BIO / SUMMARY -->
                        <div v-if="employeeDetails.employee && employeeDetails.employee.Summary">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Summary`) }}</h6>
                            <div class="detail-field">
                                {{ employeeDetails.employee.Summary }}
                            </div>
                        </div>

                        <!-- SOCIAL LINKS -->
                        <b-col md="6" sm="12" v-if="socialsList.find(social => employeeDetails.employee[social])">
                            <cv-social-links :candidateDetails="employeeDetails.employee"></cv-social-links>
                        </b-col>

                        <!-- Presentation -->
                        <div v-if="employeeDetails.employee && employeeDetails.employee.Presentation">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Presentation`) }}</h6>
                            <div class="detail-field">
                                {{ employeeDetails.employee.Presentation }}
                            </div>
                        </div>

                        <!-- Skills -->
                        <div v-if="employeeDetails.skills && employeeDetails.skills.length > 0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Skills`) }}</h6>
                            <div class="detail-field">
                                <b-row>
                                    <b-col cols="6">

                                    </b-col>
                                    <b-col cols="4"
                                           class="
                                           text-center
                                           text-uppercase
                                           text-muted
                                           font-small-2
                                            ">
                                        {{ i18nT(`Level`) }}
                                    </b-col>
                                </b-row>

                                <b-row
                                    v-for="(skill,index) in employeeDetails.skills"
                                    :key="'skills-'+index"
                                >
                                    <b-col cols="6">
                                        <span class="tag-container">
                                            {{ skill.Label }}
                                        </span>
                                    </b-col>
                                    <b-col cols="4" class="text-center pt-05">
                                        <b-progress
                                            :value="skill.LevelId"
                                            variant="primary"
                                            :max="3"></b-progress>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>

                        <!-- Languages -->
                        <div v-if="employeeDetails.languages && employeeDetails.languages.length > 0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Languages`) }}</h6>
                            <div class="detail-field px-1 pb-05">
                                <b-row>
                                    <b-col cols="4">

                                    </b-col>
                                    <b-col cols="4"
                                           class="
                                           text-center
                                           text-uppercase
                                           text-muted
                                           font-small-2
                                            ">
                                        {{ i18nT(`Spoken`) }}
                                    </b-col>
                                    <b-col cols="4"
                                           class="
                                           text-center
                                           text-uppercase
                                           text-muted
                                           font-small-2
                                            "
                                    >
                                        {{ i18nT(`Written`) }}
                                    </b-col>
                                </b-row>

                                <b-row v-for="(language,index) in employeeDetails.languages"
                                       :key="'language-'+index"
                                       class="pb-05 pt-05"
                                       :class="{'border-bottom': index < employeeDetails.languages.length - 1}"
                                >
                                    <b-col cols="4">
                                        {{ language.Language }}
                                    </b-col>
                                    <b-col cols="4" class="text-center pt-05">
                                        <b-progress
                                            :value="language.SpeakId"
                                            variant="primary"
                                            :max="5"></b-progress>

                                    </b-col>
                                    <b-col cols="4" class="text-center pt-05">
                                        <b-progress
                                            :value="language.WriteId"
                                            variant="primary"
                                            :max="5"></b-progress>
                                    </b-col>
                                </b-row>


                            </div>
                        </div>


                        <!-- Experience -->
                        <div v-if="employeeDetails.jobs && employeeDetails.jobs.length >0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Work Experience`) }}</h6>
                            <div class="detail-field">
                                <jobs :data="employeeDetails.jobs"/>
                            </div>
                        </div>

                        <!-- Education -->
                        <div v-if="employeeDetails.education && employeeDetails.education.length > 0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Education`) }}</h6>
                            <div class="detail-field">
                                <educations :data="employeeDetails.education"/>
                            </div>
                        </div>

                        <!-- CERTIFICATES -->

                        <div v-if="employeeDetails.certificates && employeeDetails.certificates.length > 0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Certificates`) }}</h6>
                            <div class="detail-field px-1">
                                <b-row
                                    v-for="(certificate,index) in employeeDetails.certificates"
                                    :key="'certificate-'+index"
                                >
                                    <b-col cols="12">
                                        <b-link :href="certificate.Url" target="_blank" >{{ certificate.Label }}</b-link>
                                    </b-col>
                                    <b-col cols="12">
                                        <p class="text-muted font-small-3">{{ certificate.Description}}</p>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>


                        <!-- References -->
                        <div v-if="employeeDetails && employeeDetails.references && employeeDetails.references.length > 0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`References`) }}</h6>

                            <div class="detail-field px-1">
                                <b-row
                                    v-for="(ref,index) in employeeDetails.references"
                                    :key="'reference-'+index"
                                >
                                    <b-col cols="12">
                                        <b-link v-if="ref.AttachedFile" :href="ref.AttachedFile.DownloadUrl" target="_blank" >{{ ref.AttachedFile.Label }}</b-link>
                                        <b-link v-else-if="ref.Url" :href="ref.Url" target="_blank" >{{ ref.Url }}</b-link>
                                    </b-col>
                                    <b-col cols="12">
                                        <p class="text-muted font-small-3">{{ref.Label}}</p>
                                    </b-col>
                                </b-row>
                            </div>

                        </div>

                        <!-- INTERESTS -->

                        <div v-if="employeeDetails.interests && employeeDetails.interests.length > 0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Interests`) }}</h6>
                            <div class="detail-field px-1">
                                <b-row
                                    v-for="(interest,index) in employeeDetails.interests"
                                    :key="'interests-'+index"
                                    class="pb-05 pt-05"
                                    :class="{'border-bottom': index < employeeDetails.interests.length - 1}"
                                >
                                    <b-col cols="12">
                                        <strong>{{ interest.Label}}</strong>
                                    </b-col>
                                    <b-col cols="12">
                                        <p class="text-muted font-small-3">{{ interest.Description}}</p>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>

<!--                        <div>-->
<!--                            <hr class="invoice-spacing"/>-->
<!--                            <h6 class="section-label">{{ i18nT(`Relocation and remote work`) }}</h6>-->
<!--                            <div class="detail-field px-1 pt-05">-->
<!--                                <p>{{ i18nT(`Willing to relocate?`) }}: <strong>{{ employeeDetails.Relocate ? i18nT(`Yes`) : i18nT(`No`) }}</strong></p>-->
<!--                                <p>{{ i18nT(`Willing to travel?`) }}: <strong>{{ employeeDetails.Travel ? i18nT(`Yes`) : i18nT(`No`) }}</strong></p>-->
<!--                                <p>{{ i18nT(`Accepts remote work?`) }}: <strong>{{ employeeDetails.Remote ? i18nT(`Yes`) : i18nT(`No`) }}</strong></p>-->
<!--                            </div>-->
<!--                        </div>-->

                    </b-card-body>

                </b-card>

            </b-col>

        </b-row>

        <!-- MODAL -->
        <b-modal
            id="modal-add-document"
            title="Assign Document"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="assignDocuments"
        >
            <validation-observer ref="assignDocumentForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <multiselect
                                v-model="documents"
                                :options="assignDocumentList"
                                :multiple="true"
                                group-values="options"
                                group-label="groupName"
                                :group-select="true"
                                :placeholder="i18nT(`Type to search`)"
                                track-by="Label"
                                label="Label"
                            >
                <span slot="noResult">
                  {{i18nT(`Oops! No elements found. Consider changing the search query.`)}}
                </span>
                            </multiselect>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
        <!-- MODAL -->
        <b-modal
            id="modal-add-task"
            title="Assign Tasks"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="assignTasks"
        >
            <validation-observer ref="assignTaskForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <v-select 
                                v-model="tasks"
                                :options="assignTaskList"
                                label="Label"
                            />
                            <multiselect
                                v-model="tasks"
                                :options="assignTaskList"
                                :multiple="true"
                                group-values="options"
                                group-label="groupName"
                                :group-select="true"
                                :placeholder="i18nT(`Type to search`)"
                                track-by="Label"
                                label="Label"
                            >
                                <span slot="noResult">
                                {{i18nT(`Oops! No elements found. Consider changing the search query.`)}}
                                </span>
                            </multiselect>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>

</template>

<script>
import {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BFormTextarea,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BForm,
    BFormRating,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BModal,
    BProgress,
    VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Timeline from './Timeline.vue'
import CvSocialLinks from "@/views/recruiting/CVComponents/CvSocialLinks.vue";
import Comments from './Comments.vue'
import Leaves from './Leaves.vue'
import Todos from './Todos.vue'
import Documents from './Documents.vue'
import Educations from './Educations.vue'
import Ratings from './Ratings.vue'
import Jobs from './Jobs.vue'
import router from '@/router'
import { dictToSelectArray } from '@core/utils/utils'
import flatPickr from 'vue-flatpickr-component'
import Multiselect from 'vue-multiselect'
import vSelect from 'vue-select'
import useAuth from '@/auth/useAuth'

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BCardHeader,
        BCardBody,
        BFormTextarea,
        BTabs,
        BTab,
        BButton,
        BAvatar,
        BFormRating,
        BFormSelect,
        Timeline,
        Comments,
        Leaves,
        Todos,
        Documents,
        Educations,
        Jobs,
        Ratings,
        BDropdown,
        BModal,
        BDropdownItem,
        BBadge,
        BLink,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        flatPickr,
        Multiselect,
        BForm,
        BFormCheckbox,
        BProgress,
        vSelect,
        CvSocialLinks
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            employeeDetails: null,
            levels: [],
            contractData: {},
            jobLevels: [],
            salaryPeriods: [
                {
                    value: 1,
                    text: this.i18nT(`year`)
                },
                {
                    value: 2,
                    text: this.i18nT(`month`)
                },
                {
                    value: 3,
                    text: this.i18nT(`week`)
                },
                {
                    value: 4,
                    text: this.i18nT(`day`)
                },
                {
                    value: 5,
                    text: this.i18nT(`hour`)
                }
            ],
            weekDays: [
                {
                    value: 'monday',
                    text: this.i18nT(`Monday`)
                },
                {
                    value: 'tuesday',
                    text: this.i18nT(`Tuesday`)
                },
                {
                    value: 'wednesday',
                    text: this.i18nT(`Wednesday`)
                },
                {
                    value: 'thursday',
                    text: this.i18nT(`Thursday`)
                },
                {
                    value: 'friday',
                    text: this.i18nT(`Friday`)
                },
                {
                    value: 'saturday',
                    text: this.i18nT(`Saturday`)
                },
                {
                    value: 'sunday',
                    text: this.i18nT(`Sunday`)
                }
            ],
            socialsList: [
                'Facebook',
                'Twitter',
                'Linkedin',
                'Instagram',
                'Youtube',
                'Pinterest',
                'Behance',
                'Github',
                'Dribble',
                'Web',
                'Tiktok'
            ],
            currency: 'EUR',
            required,
            comment: '',
            todo: '',
            ratingComment: '',
            ratingTypes: [],
            contractTypes: [],
            ratingType: '',
            rating: 0,
            interviewFrom: '',
            interviewTo: '',
            interviewWith: '',
            interviewLocation: '',
            interviewComment: '',
            performanceRatingNames: [],
            performanceRatingName: null,
            performanceRating: 0,
            performanceRatingPeriodTypes: [],
            performanceRatingPeriodType: null,
            performanceRatingBiAnualOptions: [],
            performanceRatingBiAnualOption: null,
            performanceRatingMonthlyOptions: [],
            performanceRatingMonthlyOption: null,
            performanceRatingQuarterlyOptions: [],
            performanceRatingQuarterlyOption: null,
            performanceRatingYearlyOptions: [],
            performanceRatingYearlyOption: null,
            performanceRatingWords: [],
            performanceRatingWord: null,
            specificPeriodStart: null,
            specificPeriodEnd: null,
            ratingTypeName: 'stars',
            performanceRatingPercentage: 50,
            documents: [],
            assignDocumentList: [],
            assignTaskList: [],
            tasks: {},
        }
    },
    created() {
        this.refresh()

        this.currency = useAuth.getCurWorkspace().MainCurrency

        this.$http.get(`settings/ratingTemplates`).then(({ data }) => {
            this.ratingTypes = data.data.map(ratingType => ({
                value: ratingType.Id,
                text: ratingType.Label
            }))

            this.ratingType = this.ratingTypes[0].value
        })

        this.$http.get(`system/departments`).then(({ data }) => {
            this.departments = dictToSelectArray(data.data)
        })

        this.$http.get(`settings/organizationLevels`).then(({ data }) => {
            this.jobLevels = data.data.map(jobLevel => ({
                value: jobLevel.Id,
                text: jobLevel.Label
            }))
        })

        this.$http.get(`system/contractTypes`).then(({ data }) => {
            this.contractTypes = dictToSelectArray(data.data)
        })

        this.$http.get(`documents/listDocumentsToAssign`).then(({ data }) => {
            this.assignDocumentList = []
            const keys = Object.keys(data.data)
            for (const key of keys) {
                this.assignDocumentList.push({
                    groupName: key
                        .split('_')
                        .map(comp => comp.charAt(0).toUpperCase() + comp.slice(1))
                        .join(' '),
                    options: data.data[key]
                })
            }
        })

        this.$http.get(`tasks/listForAssign`).then(({ data }) => {
            this.assignTaskList = []
            const keys = Object.keys(data.data)
            for (const key of keys) {
                this.assignTaskList.push({
                    groupName: key
                    .split('_')
                    .map(comp => comp.charAt(0).toUpperCase() + comp.slice(1))
                    .join(' '),
                    options: data.data[key]
                })
            }
            console.log("assignTaskList: ", this.assignTaskList)

            this.assignTaskList = data.data.tasks.filter(task => task.AssignedTo === 0)
        })
    },
    methods: {
        removeTask(task) {
            console.log(task)
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`tasks?id=${task.Id}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The task has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refresh()
                    })
                }
            })
        },
        onDelete(){
            this.$swal({
                title: this.i18nT(`Are you sure you want to delete this employee?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText:  this.i18nT('Yes, delete it!'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    const employeeId = router.currentRoute.params.id;
                    this.$http.delete(`employees?id=${employeeId}`).then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })

                        this.$router.push({ name: 'employees' })
                    });

                }
            });

        },
        worksOnThisDay(day) {
            return this.contractData.WorkWeek.includes(day)
        },
        weekdayLabel(day){
            const weekDay = this.weekDays.find(
                weekDay => weekDay.value == day
            )
            return weekDay ? weekDay.text : ''
        },
        salaryPeriodLabel(id) {
            const salaryPeriod = this.salaryPeriods.find(
                salaryPeriod => salaryPeriod.value == id
            )
            return salaryPeriod ? salaryPeriod.text : ''
        },

        departmentLabel(id) {
            const department = this.departments.find(
                department => department.value == id
            )
            return department ? department.text : ''
        },
        contractTypeLabel(id) {
            const contractType = this.contractTypes.find(
                contractType => contractType.value == id
            )
            return contractType ? contractType.text : ''
        },
        jobLevelLabel(id) {
            const jobLevel = this.jobLevels.find(jobLevel => jobLevel.value == id)
            return jobLevel ? jobLevel.text : ''
        },
        refresh() {
            this.$http
                .get(`employees?id=${router.currentRoute.params.id}`)
                .then(({ data }) => {
                    this.employeeDetails = data.data
                    this.contractData = data.data.current_application

                    this.contractData.WorkWeek = this.contractData.WorkWeek && this.contractData.WorkWeek.split(',') || [];
                    this.performanceRatingNames = dictToSelectArray(
                        this.employeeDetails.performance_rating_names
                    )
                    this.performanceRatingWords = dictToSelectArray(
                        this.employeeDetails.performance_rating_words
                    )
                    this.performanceRatingPeriodTypes = dictToSelectArray(
                        this.employeeDetails.performance_rating_period
                    )
                    this.performanceRatingBiAnualOptions = dictToSelectArray(
                        this.employeeDetails.performance_rating_period_bi_annual
                    )
                    this.performanceRatingMonthlyOptions = dictToSelectArray(
                        this.employeeDetails.performance_rating_period_monthly
                    )
                    this.performanceRatingQuarterlyOptions = dictToSelectArray(
                        this.employeeDetails.performance_rating_period_quarterly
                    )
                    this.performanceRatingYearlyOptions = dictToSelectArray(
                        this.employeeDetails.performance_rating_period_years
                    )

                    console.log(this.employeeDetails)
                })
        },
        publishComment() {
            this.$refs.commentForm.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    const formData = new FormData()
                    formData.append(
                        'application_id',
                        this.employeeDetails.applications[0].Id
                    )
                    formData.append('comment', this.comment)

                    this.$http.post('employees/addComment', formData).then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.i18nT(`Commend added`),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.comment = ''
                        this.$refs.commentForm.reset()
                        this.refresh()
                    })
                }
            })
        },
        publishTodo() {
            this.$refs.todoForm.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    const formData = new FormData()
                    formData.append(
                        'application_id',
                        this.employeeDetails.applications[0].Id
                    )
                    formData.append('todo_comment', this.todo)

                    this.$http.post('employees/addTodo', formData).then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.i18nT(`To-Do added`),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.todo = ''
                        this.$refs.todoForm.reset()
                        this.refresh()
                    })
                }
            })
        },
        publishRating() {
            this.$refs.ratingForm.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    const formData = new FormData()
                    formData.append(
                        'application_id',
                        this.employeeDetails.applications[0].Id
                    )
                    formData.append('performance_name', this.performanceRatingName)
                    formData.append(
                        'performance_period',
                        this.performanceRatingPeriodType
                    )
                    formData.append('performance_comment', this.ratingComment)

                    if (this.performanceRatingPeriodType == 'monthly') {
                        formData.append(
                            'performancemonth_period',
                            this.performanceRatingMonthlyOption
                        )
                        formData.append(
                            'performancemonthyear_period',
                            this.performanceRatingYearlyOption
                        )
                    } else if (this.performanceRatingPeriodType == 'quarterly') {
                        formData.append(
                            'performancequarterly_period',
                            this.performanceRatingQuarterlyOption
                        )
                        formData.append(
                            'performancequarterlyyear_period',
                            this.performanceRatingYearlyOption
                        )
                    } else if (this.performanceRatingPeriodType == 'bi_annual') {
                        formData.append(
                            'performancebi_annual_period',
                            this.performanceRatingBiAnualOption
                        )
                        formData.append(
                            'performancebi_annualyyear_period',
                            this.performanceRatingYearlyOption
                        )
                    } else if (this.performanceRatingPeriodType == 'yearly') {
                        formData.append(
                            'performanceyearlyyear_period',
                            this.performanceRatingYearlyOption
                        )
                    } else if (this.performanceRatingPeriodType == 'specific_period') {
                        formData.append(
                            'performancespecific_period_start',
                            this.specificPeriodStart
                        )
                        formData.append(
                            'performancespecific_period_end',
                            this.specificPeriodEnd
                        )
                    }

                    if (this.ratingTypeName == 'stars') {
                        formData.append('performancestar_rating', this.rating)
                    } else if (this.ratingTypeName == 'percentage') {
                        formData.append(
                            'performancepercentage_rating',
                            this.performanceRatingPercentage
                        )
                    } else if (this.ratingTypeName == 'words') {
                        formData.append(
                            'performanceword_rating',
                            this.performanceRatingWord
                        )
                    }

                    this.$http.post('employees/addPerformance', formData).then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Rating added`,
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.ratingComment = ''
                        this.ratingType = ''
                        this.rating = 3
                        this.$refs.ratingForm.reset()
                        this.refresh()
                    })
                }
            })
        },
        publishInterview() {
            this.$refs.interviewForm.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    const formData = new FormData()
                    formData.append(
                        'application_id',
                        this.employeeDetails.applications[0].Id
                    )
                    formData.append(
                        'interview_when',
                        `${this.interviewFrom} - ${this.interviewTo}`
                    )
                    formData.append('interview_with', this.interviewWith)
                    formData.append('interview_location', this.interviewLocation)
                    formData.append('interview_comment', this.interviewComment)

                    this.$http.post('employees/addInterview', formData).then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Interview added`,
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.interviewFrom = ''
                        this.interviewTo = ''
                        this.interviewWith = ''
                        this.interviewLocation = ''
                        this.interviewComment = ''
                        this.$refs.interviewForm.reset()
                        this.refresh()
                    })
                }
            })
        },
        openAssignDocumentModal() {
            this.$bvModal.show('modal-add-document')
        },
        openAssignTaskModal() {
            this.$bvModal.show('modal-add-task')
        },
        onDeleteDocument(document) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .post(`documents/unassign?document_id=${document.Id}&user_id=${this.employeeDetails.employee.Id}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refresh()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        assignTasks(event) {
            event.preventDefault()
            if (this.tasks.Id) {
                const formData = new FormData()
                formData.append('freelancer_id', this.employeeDetails.employee.Id)
                formData.append(
                    'tasks_to_assign',
                    this.tasks.Id
                )
                this.$http
                    .post('tasks/assign', formData)
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.tasks = {}
                        this.$bvModal.hide('modal-add-task')

                        this.refresh()
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
            }
        },
        assignDocuments(event) {
            event.preventDefault()
            if (this.documents.length > 0) {
                const formData = new FormData()
                formData.append(
                    'application_id',
                    this.employeeDetails.current_application.Id
                )
                formData.append(
                    'documents_to_add',
                    this.documents.map(task => task.Id).join(',')
                )
                this.$http
                    .post('documents/assign', formData)
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.documents = []
                        this.$bvModal.hide('modal-add-document')
                        this.refresh()
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
            }
        },
        onFinishTask(task) {
            const formData = new FormData()
            formData.append('id', task.Id)
            formData.append('completed', 1 - task.Completed)
            this.$http
                .post('tasks/completedStatus', formData)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.refresh()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        onDeleteComment(comment) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`employees/comment?id=${comment.Id}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The comment has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refresh()
                    })
                }
            }).catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        onDeletePerformanceRating(performanceRating) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`employees/performance?id=${performanceRating.Id}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text: this.i18nT(`The performance rating has been deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.refresh()
                        })
                }
            }).catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        onDeleteTodo(todo) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`employees/todo?id=${todo.Id}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The todo has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refresh()
                    })
                }
            }).catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                })
            })
        },
        onDeleteInterview(interview) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`employees/interview?id=${interview.Id}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text: this.i18nT(`The interview has been deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.refresh()
                        })
                }
            }).catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                })
            })
        },
        onDeleteRating(rating) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`employees/rating?id=${rating.Id}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The rating has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refresh()
                    })
                }
            }).catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                })
            })
        }
    },
    watch: {
        performanceRatingName: function() {
            this.ratingTypeName = this.performanceRatingName.split('|')[1]
        }
    },
    computed: {
        employeeRating() {
            if(this.employeeDetails && this.employeeDetails.rating && this.employeeDetails.rating.rating) {
                return this.employeeDetails.rating.rating;
            }else {
                return 0;
            }
        },
        employeeId() {
            return router.currentRoute.params.id
        }
    }
}
</script>

<style lang="scss">

.nav-tabs .nav-link {
    display: block;
}

.section-container {
    border: solid 1px #eeeeee;
    border-radius: 8px;
}
.item-rating {
    display: inline-block;
    margin-left: 6px;

    ul {
        margin-bottom: 0px;
    }
}
.info-item {
    span {
        margin-left: 6px;
    }
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;
    margin-right: 8px !important;
    .handler {
        cursor: pointer;
    }
}

.task-container {
    background-color: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    margin-top: 8px;
    padding: 12px;
    position: relative;

    .completed {
        text-decoration: line-through;
    }
    .task-type {
        font-size: 12px;
    }
    .removeBtn {
        position: absolute !important;
        right: 10px !important;
        bottom: 10px !important;
    }
}

.detail-field {
    min-height: 32px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
