<template>
  <app-timeline>
    <app-timeline-item
      v-for="(job,index) in data"
      :key="index"
      :title="`${job.Label} | ${job.CompanyName}`"
      :subtitle="job.Description"
      icon="TruckIcon"
      variant="success"
      :time="`${job.StartDate} ~ ${job.EndDate}`"
      :showDelete="false"
    />

  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from './CandidateTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
  }
}
</script>
