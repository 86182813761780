<template>
  <app-timeline>
    <app-timeline-item
      v-for="(document,index) in data"
      :key="index"
      :title="document.Label"
      :subtitle="document.Description + ''"
      icon="FileIcon"
      :time="niceDate(document.UpdatedAt)"
      variant="success"
      :data="document"
      :show-delete="true"
      :onDelete="onDelete"
    />

  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from './CandidateTimelineItem.vue'
import moment from 'moment'

export default {
  components: {
    AppTimeline,
    AppTimelineItem
  },
  data() {
    return {}
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    onDelete: {
      type: Function,
      default: null
    }
  },
  methods: {
    niceDate(inputDate) {
      return moment(inputDate).format('Do MMM mm:HH')
    }
  }
}
</script>
